import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div>
      <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">About Us</h4>
          <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
            <li className="breadcrumb-item active text-primary">About</li>
          </ol>
        </div>
      </div>

      {/* Modal Search Start */}
      <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="exampleModalLabel">Search by keyword</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body d-flex align-items-center">
              <div className="input-group w-75 mx-auto d-flex">
                <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                <span id="search-icon-1" className="input-group-text btn border p-3"><i className="fa fa-search text-white"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Search End */}

      {/* About Start */}
      <div className="container-fluid about overflow-hidden py-5">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="about-img rounded h-100">
                    <img
                        src="img/about.jpg"
                        className="img-fluid rounded h-100 w-100"
                        style={{ objectFit: 'cover' }}
                        alt="Water Purifier"
                    />
                    <div className="about-exp">
                        <span>20 Years of Excellence</span>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                <div className="about-item">
                    <h4 className="text-primary text-uppercase">About Us</h4>
                    <h1 className="display-3 mb-3">Delivering Quality Water Solutions</h1>
                    <p className="mb-4">
                        For over two decades, we have been committed to providing innovative and reliable water purification systems that ensure the best drinking water for homes and businesses worldwide.
                    </p>
                    <div className="bg-light rounded p-4 mb-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex">
                                    <div className="pe-4">
                                        <div
                                            className="rounded-circle bg-primary d-flex align-items-center justify-content-center"
                                            style={{ width: '80px', height: '80px' }}
                                        >
                                            <i className="fas fa-tint text-white fa-2x"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" className="h4 d-inline-block mb-3">Trusted by Millions</a>
                                        <p className="mb-0">
                                            Our purifiers serve millions of satisfied customers, delivering safe and pure water every day.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-light rounded p-4 mb-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex">
                                    <div className="pe-4">
                                        <div
                                            className="rounded-circle bg-primary d-flex align-items-center justify-content-center"
                                            style={{ width: '80px', height: '80px' }}
                                        >
                                            <i className="fas fa-faucet text-white fa-2x"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" className="h4 d-inline-block mb-3">Top-Quality Standards</a>
                                        <p className="mb-0">
                                            Our products adhere to the highest industry standards, ensuring your water is always safe and refreshing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="contact" className="btn btn-secondary rounded-pill py-3 px-5">Contact US</a>
                </div>
            </div>
        </div>
    </div>
</div>



      {/* About End */}

      {/* Fact Counter */}
      <div className="container-fluid counter py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-thumbs-up fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Happy Clients</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">456</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-truck fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Delivered Units</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">513</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-users fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Employees</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">53</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-calendar-alt fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Years of Experience</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">20</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fact Counter End */}

      {/* Feature Start */}
      <div className="container-fluid feature bg-light py-5">
    <div className="container py-5">
        <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: '800px' }}
        >
            <h4 className="text-uppercase text-primary">Our Feature</h4>
            <h1 className="display-3 text-capitalize mb-3">
                Leading Innovator in Water Purification
            </h1>
        </div>
        <div className="row g-4">
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s" >
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3" >
                        <i className="fas fa-hand-holding-water text-white fa-3x"></i>
                    </div>
                    <a href="contact" className="h4 mb-3">Rigorous Quality Control</a>
                    <p className="mb-3">
                        We ensure every purifier undergoes a strict quality check to meet the highest standards for safe and pure drinking water.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-filter text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Advanced Filtration Technology</a>
                    <p className="mb-3">
                        Our 7-step filtration process removes up to 99.9% of contaminants, providing clean, healthy, and great-tasting water.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-recycle text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Eco-Friendly Design</a>
                    <p className="mb-3">
                        Designed with sustainability in mind, our purifiers minimize waste and promote the efficient use of water and energy.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-microscope text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Lab-Tested Purity</a>
                    <p className="mb-3">
                        Each product is lab-tested to ensure the water it produces meets the highest health and safety standards globally.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  );
}

export default About;
