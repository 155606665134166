import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // Adjust the path if necessary

const container = document.getElementById('root');

// Make sure this block is correct and executes when the DOM is loaded
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error('Target container is not a DOM element');
}
