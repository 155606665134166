import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo (2).png'
import './Header.css'


function Header() {
  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
           
            <img src={logo} alt="Acuas Logo"  style={{ width: '100px', height: '250px' }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link">Home</Link>
              <Link to="/about" className="nav-item nav-link">About</Link>
              <Link to="/service" className="nav-item nav-link">Services</Link>
              <Link to="/product" className="nav-item nav-link">Products</Link>
              <Link to="/contact" className="nav-item nav-link">Contact</Link>
            </div>
            <div className="d-none d-xl-flex me-3">
              <div className="d-flex flex-column pe-3 border-end border-primary">
                <span className="text-body">Get Free Delivery</span>
                <a href="tel:+01234567890">
                  <span className="text-primary">Free: + 919822261313</span>
                </a>
              </div>
            </div>
           
            <a href="contact" className="btn btn-primary rounded-pill d-inline-flex flex-shrink-0 py-2 px-4">
              Order Now
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
