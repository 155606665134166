import React from 'react';

const Footer = () => (
  <>
    <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
      <div className="container py-5">
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-lg-7">
           
          </div>
         
        </div>
        <div className="row g-5">
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <div className="footer-item">
                <h3 className="text-white mb-4">
                  <i className="fas fa-water text-primary me-3"></i>
                 Neeva Purifiers
                </h3>
                <p className="mb-3">
                  At Neeva, we provide advanced water purification solutions to ensure you and your family enjoy clean and safe drinking water. Our products use the latest technology to deliver the highest quality.
                </p>
              </div>
             
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="text-white mb-4">Quick Links</h4>
              <a href="/"><i className="fas fa-angle-right me-2"></i> Home</a>
              <a href="about"><i className="fas fa-angle-right me-2"></i> About Us</a>
              <a href="service"><i className="fas fa-angle-right me-2"></i> Services</a>
              <a href="product"><i className="fas fa-angle-right me-2"></i> Products</a>
              <a href="contact"><i className="fas fa-angle-right me-2"></i> Contact Us</a>
              
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="text-white mb-4">Business Hours</h4>
              <div className="mb-3">
                <h6 className="text-muted mb-0">Mon - Friday:</h6>
                <p className="text-white mb-0">09.00 am to 08.00 pm</p>
              </div>
              <div className="mb-3">
                <h6 className="text-muted mb-0">Saturday:</h6>
                <p className="text-white mb-0">09.00 am to 08.00 pm</p>
              </div>
              <div className="mb-3">
                <h6 className="text-muted mb-0">Vacation:</h6>
                <p className="text-white mb-0">All Sundays are closed</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="text-white mb-4">Contact Info</h4>
              <a href="#"><i className="fa fa-map-marker-alt me-2"></i> S.NO. 83, UPPAR GROUND FLOOR, OPP. AKASH PLYWOOD, NEW B.J. MARKET, JALGAON</a>
          
              <a href="tel:+919822261313"><i className="bi bi-telephone phone-icon me-2"></i> +919822261313</a>
              <a href="tel:+919822261313" className="mb-3"><i className="fas fa-print me-2"></i> +919822261313</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid copyright py-4">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-md-6 text-center text-md-start mb-md-0">
            <span className="text-body">
              <a href="#" className="border-bottom text-white">
                Neeva <i className="fas fa-copyright text-light me-2"></i>Purifiers
              </a>, All rights reserved.
            </span>
          </div>
          <div className="col-md-6 text-center text-md-end text-body">
            Designed By Shree Digital Marketing Agency
          </div>
        </div>
      </div>
    </div>

    <a href="#" className="btn btn-secondary btn-lg-square rounded-circle back-to-top">
      <i className="fa fa-arrow-up"></i>
    </a>
  </>
);

export default Footer;
