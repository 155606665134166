import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // You can handle the form submission here if needed
    alert('Thank you for reaching out! We will get back to you soon. For immediate support, you can also contact us on WhatsApp.');

    // Optionally, you could redirect users to WhatsApp after submission
    window.location.href = `https://wa.me/9822261313?text=${encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nSubject: ${formData.subject}\nMessage: ${formData.message}`)}`;
  };

  return (
    <div>
      <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h4>
          <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item"><a href="#">Pages</a></li>
            <li className="breadcrumb-item active text-primary">Contact</li>
          </ol>
        </div>
      </div>

      {/* Modal Search */}
      <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="exampleModalLabel">Search by keyword</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body d-flex align-items-center">
              <div className="input-group w-75 mx-auto d-flex">
                <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                <span id="search-icon-1" className="input-group-text btn border p-3">
                  <i className="fa fa-search text-white"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="container-fluid contact bg-light py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 h-100 wow fadeInUp" data-wow-delay="0.2s">
              <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
                <h4 className="text-uppercase text-primary">Get in Touch</h4>
                <h1 className="display-3 text-capitalize mb-3">We’re Here to Help</h1>
                <p className="mb-0">
                  For inquiries about our water purifiers, customer support, or product details, please use the form below. Our team is ready to assist you with any questions or concerns you may have.
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row g-4">
                  <div className="col-lg-12 col-xl-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control border-0"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control border-0"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <div className="form-floating">
                      <input
                        type="phone"
                        className="form-control border-0"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <label htmlFor="phone">Your Phone</label>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control border-0"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control border-0"
                        placeholder="Leave a message here"
                        id="message"
                        name="message"
                        style={{ height: '175px' }}
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3">Send Message</button>
                  </div>
                </div>
              </form>
              <div className="text-center mt-4">
                <p>Or contact us directly on WhatsApp:</p>
                <a href={`https://wa.me/9822261313`} className="btn btn-success">Chat on WhatsApp</a>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="row g-4">
                <div className="col-12">
                  <div className="d-inline-flex rounded bg-white w-100 p-4">
                    <i className="fas fa-map-marker-alt fa-2x text-secondary me-4"></i>
                    <div>
                      <h4>Address</h4>
                      <p className="mb-0">S.NO. 83, UPPAR GROUND FLOOR, OPP. AKASH PLYWOOD, NEW B.J. MARKET, JALGAON</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6">
                  <div className="d-inline-flex rounded bg-white w-100 p-4">
                    <i className="fas fa-envelope fa-2x text-secondary me-4"></i>
                    <div>
                      <h4>Mail Us</h4>
                      <p className="mb-0">info@example.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6">
                  <div className="d-inline-flex rounded bg-white w-100 p-4">
                    <i className="fa fa-phone-alt fa-2x text-secondary me-4"></i>
                    <div>
                      <h4>Telephone</h4>
                      <p className="mb-0">+919822261313</p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="h-100 overflow-hidden">
                  <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14898.251545251998!2d75.567695!3d21.010152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90fa5c532cffb%3A0xec39621fe13db74a!2sHygine%20Water%20Solutions!5e0!3m2!1sen!2sin!4v1725945753361!5m2!1sen!2sin"
  width="600"
  height="450"
  style={{ border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
