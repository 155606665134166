import React, { useState, Suspense } from 'react';
import filter from './images/DSC06876-removebg-preview.png'
import filter1 from './images/fil1.jpg'
import filter2 from './images/fil2.jpg'
import filter3 from './images/fil3.jpg'
import filter4 from './images/fil4.jpg'
import filter5 from './images/fil5.jpg'
import filter6 from './images/fil6.jpg'
import filter7 from './images/fil7.jpg'
import filter8 from './images/fil8.jpg'
import filter9 from './images/fil9.jpg'
import filter10 from './images/fil10.jpg'
import filter11 from './images/fil11.jpg'
import filter12 from './images/fil12.jpg'
import filter13 from './images/fil13.jpg'
import filter14 from './images/fil14.jpg'
import filter15 from './images/fil15.jpg'
import filter16 from './images/fil16.jpg'
import filter17 from './images/fil17.jpg'
import filter18 from './images/fil18.jpg'
import filter19 from './images/fil19.jpg'
import filter20 from './images/fil20.jpg'
import filter21 from './images/fil21.jpg'
import filter22 from './images/fil22.jpg'
import filter23 from './images/fil23.jpg'
import filter24 from './images/fil24.jpg'
import filter25 from './images/fil25.jpg'
import filter26 from './images/fil26.jpg'
import filter27 from './images/fil27.jpg'
import filter28 from './images/fil28.jpg'
import filter29 from './images/fil29.jpg'
import filter30 from './images/fil30.jpg'
import filter31 from './images/fil31.jpg'
import filter32 from './images/fil32.jpg'
import filter33 from './images/fil33.jpg'
import filter34 from './images/fil34.jpg'
import filter35 from './images/fil35.jpg'
import filter36 from './images/fil36.jpg'
import filter37 from './images/fil37.jpg'
import filter38 from './images/fil38.jpg'
import filter39 from './images/fil39.jpg'
import filter40 from './images/fil40.jpg'
import filter41 from './images/fil41.jpg'
import filter42 from './images/fil42.jpg'
import './Home.css'
const products = [
  { src: filter31, name: 'Filter 31' },
  { src: filter32, name: 'Filter 32' },
  { src: filter33, name: 'Filter 33' },
  { src: filter34, name: 'Filter 34' },
  { src: filter35, name: 'Filter 35' },
  { src: filter36, name: 'Filter 36' },
  { src: filter37, name: 'Filter 37' },
  { src: filter38, name: 'Filter 38' },
  { src: filter39, name: 'Filter 39' },
  { src: filter40, name: 'Filter 40' },
  { src: filter41, name: 'Filter 41' },
  { src: filter42, name: 'Filter 42' },
  { src: filter1, name: 'Filter 1' },
  { src: filter2, name: 'Filter 2' },
  { src: filter3, name: 'Filter 3' },
  { src: filter4, name: 'Filter 4' },
  { src: filter5, name: 'Filter 5' },
  { src: filter6, name: 'Filter 6' },
  { src: filter7, name: 'Filter 7' },
  { src: filter8, name: 'Filter 8' },
  { src: filter9, name: 'Filter 9' },
  { src: filter10, name: 'Filter 10' },
  { src: filter11, name: 'Filter 11' },
  { src: filter12, name: 'Filter 12' },
  { src: filter13, name: 'Filter 13' },
  { src: filter14, name: 'Filter 14' },
  { src: filter15, name: 'Filter 15' },
  { src: filter16, name: 'Filter 16' },
  { src: filter17, name: 'Filter 17' },
  { src: filter18, name: 'Filter 18' },
  { src: filter19, name: 'Filter 19' },
  { src: filter20, name: 'Filter 20' },
  { src: filter21, name: 'Filter 21' },
  { src: filter22, name: 'Filter 22' },
  { src: filter23, name: 'Filter 23' },
  { src: filter24, name: 'Filter 24' },
  { src: filter25, name: 'Filter 25' },
  { src: filter26, name: 'Filter 26' },
  { src: filter27, name: 'Filter 27' },
  { src: filter28, name: 'Filter 28' },
  { src: filter29, name: 'Filter 29' },
  { src: filter30, name: 'Filter 30' },
];





function Home() {
  const [quantities, setQuantities] = useState(
    products.reduce((acc, product) => {
      acc[product.name] = 1;
      return acc;
    }, {})
  );

  const handleQuantityChange = (productName, e) => {
    setQuantities({
      ...quantities,
      [productName]: Number(e.target.value),
    });
  };

  const handleBuyNow = (productName) => {
    const quantity = quantities[productName];
    const message = `Product: ${productName}\nQuantity: ${quantity}`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '919822261313'; // Replace with your WhatsApp number
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    
    window.open(whatsappURL, '_blank');
  };

  // Placeholder component
  const Placeholder = () => (
    <div style={{ height: '200px', background: '#f0f0f0', borderRadius: '10px' }}></div>
  );




  return (
    <div>
<div className="carousel-header">
  <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
    <ol className="carousel-indicators">
      <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
      <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
    </ol>
    <div className="carousel-inner" role="listbox">
      <div className="carousel-item active">
        <img src="img/carousel-1.jpg" className="img-fluid w-100" alt="Water Purifier" />
        <div className="carousel-caption carousel-caption-1">
          <div className="carousel-caption-content">
            <h4
              className="text-white text-uppercase fw-bold mb-4 fadeInLeft animated"
              data-animation="fadeInLeft"
              data-delay="1s"
              style={{ animationDelay: '1s', letterSpacing: '3px' }}
            >
              Advanced Water Purification
            </h4>
            <h1
              className="display-2 text-capitalize text-white mb-4 fadeInLeft animated"
              data-animation="fadeInLeft"
              data-delay="1.3s"
              style={{ animationDelay: '1.3s' }}
            >
              Pure, Safe Water for Your Family
            </h1>
            <p
              className="mb-5 fs-5 text-white fadeInLeft animated"
              data-animation="fadeInLeft"
              data-delay="1.5s"
              style={{ animationDelay: '1.5s' }}
            >
              Our state-of-the-art water purifiers remove 99.9% of contaminants, ensuring clean and healthy water for you and your loved ones. With multi-stage filtration, you can trust that every drop is pure.
            </p>
            <div
              className="carousel-caption-content-btn fadeInLeft animated"
              data-animation="fadeInLeft"
              data-delay="1.7s"
              style={{ animationDelay: '1.7s' }}
            >
              <a className="btn btn-primary rounded-pill flex-shrink-0 py-3 px-5 me-2" href="contact" >
                Shop Now
              </a>
              
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img src="img/carousel-2.jpg" className="img-fluid w-100" alt="Water Purifier Features" />
        <div className="carousel-caption carousel-caption-2">
          <div className="carousel-caption-content" style={{ maxWidth: '900px' }}>
            <h4
              className="text-white text-uppercase fw-bold mb-4 fadeInRight animated"
              data-animation="fadeInRight"
              data-delay="1s"
              style={{ animationDelay: '1s', letterSpacing: '3px' }}
            >
              Why Choose Our Purifiers?
            </h4>
            <h1
              className="display-2 text-capitalize text-white mb-4 fadeInRight animated"
              data-animation="fadeInRight"
              data-delay="1.3s"
              style={{ animationDelay: '1.3s' }}
            >
              7-Stage Filtration & Smart Features
            </h1>
            <p
              className="mb-5 fs-5 text-white fadeInRight animated"
              data-animation="fadeInRight"
              data-delay="1.5s"
              style={{ animationDelay: '1.5s' }}
            >
              Our purifiers offer advanced filtration stages, including UV, RO, and Carbon filters. Integrated smart features like auto-shutoff and real-time water quality monitoring ensure maximum efficiency.
            </p>
            <div
              className="carousel-caption-content-btn fadeInRight animated"
              data-animation="fadeInRight"
              data-delay="1.7s"
              style={{ animationDelay: '1.7s' }}
            >
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#carouselId"
      data-bs-slide="prev"
    >
      <span
        className="carousel-control-prev-icon btn btn-primary fadeInLeft animated"
        aria-hidden="true"
        data-animation="fadeInLeft"
        data-delay="1.1s"
        style={{ animationDelay: '1.3s' }}
      >
        <i className="fa fa-angle-left fa-3x"></i>
      </span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#carouselId"
      data-bs-slide="next"
    >
      <span
        className="carousel-control-next-icon btn btn-primary fadeInRight animated"
        aria-hidden="true"
        data-animation="fadeInRight"
        data-delay="1.1s"
        style={{ animationDelay: '1.3s' }}
      >
        <i className="fa fa-angle-right fa-3x"></i>
      </span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div>




<div className="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-fullscreen">
        <div className="modal-content rounded-0">
            <div className="modal-header">
                <h4 className="modal-title mb-0" id="exampleModalLabel">Search for Water Purifiers</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                    <input type="search" className="form-control p-3" placeholder="Search by model, type, or feature" aria-describedby="search-icon-1"/>
                    <span id="search-icon-1" className="input-group-text btn border p-3"><i className="fa fa-search text-white"></i></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="container-fluid feature bg-light py-5">
    <div className="container py-5">
        <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: '800px' }}
        >
            <h4 className="text-uppercase text-primary">Our Feature</h4>
            <h1 className="display-3 text-capitalize mb-3">
                Leading Innovator in Water Purification
            </h1>
        </div>
        <div className="row g-4">
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s" >
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3" >
                        <i className="fas fa-hand-holding-water text-white fa-3x"></i>
                    </div>
                    <a href="contact" className="h4 mb-3">Rigorous Quality Control</a>
                    <p className="mb-3">
                        We ensure every purifier undergoes a strict quality check to meet the highest standards for safe and pure drinking water.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-filter text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Advanced Filtration Technology</a>
                    <p className="mb-3">
                        Our 7-step filtration process removes up to 99.9% of contaminants, providing clean, healthy, and great-tasting water.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-recycle text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Eco-Friendly Design</a>
                    <p className="mb-3">
                        Designed with sustainability in mind, our purifiers minimize waste and promote the efficient use of water and energy.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                <div className="feature-item p-4" style={{height:'70vh'}}>
                    <div className="feature-icon mb-3">
                        <i className="fas fa-microscope text-white fa-3x"></i>
                    </div>
                    <a href="#" className="h4 mb-3">Lab-Tested Purity</a>
                    <p className="mb-3">
                        Each product is lab-tested to ensure the water it produces meets the highest health and safety standards globally.
                    </p>
                    <a href="contact" className="btn text-secondary">
                        Know More <i className="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>






    

<div className="container-fluid counter py-5">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
        <div className="counter-item">
          <div className="counter-item-icon mx-auto">
            <i className="fas fa-thumbs-up fa-3x text-white"></i>
          </div>
          <h4 className="text-white my-4">Happy Clients</h4>
          <div className="counter-counting">
            <span className="text-white fs-2 fw-bold" data-toggle="counter-up">456</span>
            <span className="h1 fw-bold text-white">+</span>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
        <div className="counter-item">
          <div className="counter-item-icon mx-auto">
            <i className="fas fa-truck fa-3x text-white"></i>
          </div>
          <h4 className="text-white my-4">Transport</h4>
          <div className="counter-counting">
            <span className="text-white fs-2 fw-bold" data-toggle="counter-up">513</span>
            <span className="h1 fw-bold text-white">+</span>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
        <div className="counter-item">
          <div className="counter-item-icon mx-auto">
            <i className="fas fa-users fa-3x text-white"></i>
          </div>
          <h4 className="text-white my-4">Employees</h4>
          <div className="counter-counting">
            <span className="text-white fs-2 fw-bold" data-toggle="counter-up">53</span>
            <span className="h1 fw-bold text-white">+</span>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
        <div className="counter-item">
          <div className="counter-item-icon mx-auto">
            <i className="fas fa-heart fa-3x text-white"></i>
          </div>
          <h4 className="text-white my-4">Years Experience</h4>
          <div className="counter-counting">
            <span className="text-white fs-2 fw-bold" data-toggle="counter-up">17</span>
            <span className="h1 fw-bold text-white">+</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="container-fluid service bg-light overflow-hidden py-5">
  <div className="container py-5">
    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
      <h4 className="text-uppercase text-primary">Our Service</h4>
      <h1 className="display-3 text-capitalize mb-3">Protect Your Family with Best Water Purifiers</h1>
    </div>
    <div className="row gx-0 gy-4 align-items-center">
      <div className="col-lg-6 col-xl-4 wow fadeInLeft" data-wow-delay="0.2s">
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Residential Water Purifiers</a>
                  <p className="mb-0">
                    Our advanced water purifiers are designed to provide safe, clean drinking water for your home.
                    Equipped with cutting-edge filtration technology, they remove contaminants, ensuring your family
                    drinks the purest water.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-hand-holding-water text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Commercial Water Purifiers</a>
                  <p className="mb-0">
                    Our commercial-grade purifiers are ideal for businesses, ensuring that your employees and clients
                    have access to clean and healthy drinking water. Efficient and reliable, they fit seamlessly into
                    any workspace.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-building text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-item rounded p-4 mb-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Filtration Plants</a>
                  <p className="mb-0">
                    We offer custom water filtration solutions for large-scale needs. Our filtration plants are built
                    with advanced technology to purify water for residential complexes, industries, and commercial
                    spaces.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-filter text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="bg-transparent">
          <img src={filter } className="img-fluid w-100" alt="Water Purifier" />
        </div>
      </div>

      <div className="col-lg-6 col-xl-4 wow fadeInRight" data-wow-delay="0.2s">
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-assistive-listening-systems text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Water Softening</a>
                  <p className="mb-0">
                    Our water softening systems are designed to reduce hardness, improve water quality, and prevent
                    scale build-up in pipes and appliances, enhancing the lifespan of your home infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-recycle text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Market Research</a>
                  <p className="mb-0">
                    We offer detailed market research on water purification technologies, helping businesses make
                    informed decisions about the best water treatment systems for their specific needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-item rounded p-4 mb-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-project-diagram text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Project Planning</a>
                  <p className="mb-0">
                    We assist in project planning for installing water purifiers and filtration plants, ensuring
                    effective execution from design to implementation for both small and large-scale projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>










  
       


</div>


  )
}

export default Home