import React from 'react';
import { Link } from 'react-router-dom';
import filter from './images/DSC06876-removebg-preview.png'



function Service() {
  return (
    <div>
      {/* Breadcrumb Start */}
      <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Services</h4>
          <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
            <li className="breadcrumb-item active text-primary">service</li>
          </ol>
        </div>
      </div>
      {/* Breadcrumb End */}

      {/* Modal Search Start */}
      <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title mb-0" id="exampleModalLabel">Search by keyword</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body d-flex align-items-center">
              <div className="input-group w-75 mx-auto d-flex">
                <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                <span id="search-icon-1" className="input-group-text btn border p-3">
                  <i className="fa fa-search text-white"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <div className="container-fluid service bg-light overflow-hidden py-5">
  <div className="container py-5">
    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
      <h4 className="text-uppercase text-primary">Our Service</h4>
      <h1 className="display-3 text-capitalize mb-3">Protect Your Family with Best Water Purifiers</h1>
    </div>
    <div className="row gx-0 gy-4 align-items-center">
      <div className="col-lg-6 col-xl-4 wow fadeInLeft" data-wow-delay="0.2s">
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Residential Water Purifiers</a>
                  <p className="mb-0">
                    Our advanced water purifiers are designed to provide safe, clean drinking water for your home.
                    Equipped with cutting-edge filtration technology, they remove contaminants, ensuring your family
                    drinks the purest water.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-hand-holding-water text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Commercial Water Purifiers</a>
                  <p className="mb-0">
                    Our commercial-grade purifiers are ideal for businesses, ensuring that your employees and clients
                    have access to clean and healthy drinking water. Efficient and reliable, they fit seamlessly into
                    any workspace.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-building text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-item rounded p-4 mb-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="service-content text-end">
                  <a href="#" className="h4 d-inline-block mb-3">Filtration Plants</a>
                  <p className="mb-0">
                    We offer custom water filtration solutions for large-scale needs. Our filtration plants are built
                    with advanced technology to purify water for residential complexes, industries, and commercial
                    spaces.
                  </p>
                </div>
                <div className="ps-4">
                  <div className="service-btn">
                    <i className="fas fa-filter text-white fa-2x"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="bg-transparent">
          <img src={filter} className="img-fluid w-100" alt="Water Purifier" />
        </div>
      </div>

      <div className="col-lg-6 col-xl-4 wow fadeInRight" data-wow-delay="0.2s">
        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-assistive-listening-systems text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Water Softening</a>
                  <p className="mb-0">
                    Our water softening systems are designed to reduce hardness, improve water quality, and prevent
                    scale build-up in pipes and appliances, enhancing the lifespan of your home infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-item rounded p-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-recycle text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Market Research</a>
                  <p className="mb-0">
                    We offer detailed market research on water purification technologies, helping businesses make
                    informed decisions about the best water treatment systems for their specific needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-item rounded p-4 mb-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="pe-4">
                  <div className="service-btn">
                    <i className="fas fa-project-diagram text-white fa-2x"></i>
                  </div>
                </div>
                <div className="service-content">
                  <a href="#" className="h4 d-inline-block mb-3">Project Planning</a>
                  <p className="mb-0">
                    We assist in project planning for installing water purifiers and filtration plants, ensuring
                    effective execution from design to implementation for both small and large-scale projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>








      <div className="container-fluid counter py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-thumbs-up fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Happy Clients</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">456</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-truck fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Transport</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">513</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-users fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Employees</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">53</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
              <div className="counter-item">
                <div className="counter-item-icon mx-auto">
                  <i className="fas fa-heart fa-3x text-white"></i>
                </div>
                <h4 className="text-white my-4">Years Experience</h4>
                <div className="counter-counting">
                  <span className="text-white fs-2 fw-bold" data-toggle="counter-up">17</span>
                  <span className="h1 fw-bold text-white">+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fact Counter End */}

    
    </div>
  );
}

export default Service;
